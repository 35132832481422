.reg-body {
    margin: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reg-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 500px;
    background-color: rgb(240, 240, 240);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.success-image {
    width: 80px;
    height: auto;
    padding: 20px;
}

.reg-success-message {
    font-size: 20px;
    font-weight: 400;
    padding: 20px;
}

.reg-success-button {
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    margin-bottom: 20px;
}

