.auto-list-container {
    background-color: #f8f3c6;
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.title {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
}

.title p {
    font-size: 16px;
    font-weight: 500;
}

.driver-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid black;
    margin-bottom: 10px;
}

.driver-card img {
    width: 50px;
    border-radius: 50%;
}

.driver-info {
    flex-grow: 1;
    padding: 0 20px;
}

.driver-info h3 {
    margin: 0;
    color: #000;
}

.driver-info p {
    margin: 5px 0;
    color: #666;
}

.call-button {
    background-color: #1438a5;
    color: white;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}

.call-button img {
    margin-right: 10px;
    width: 20px;
    height: auto;
}

.toggle-button {
    background-color: #1438a5;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.toggle-button:hover {
    background-color: #0f2f8c;
}

/* Media queries for mobile devices */
@media (max-width: 600px) {
    .auto-list-container {
        margin-left: 5%;
        margin-right: 5%;
        padding-bottom: 30%;
        margin-bottom: 100px;
    }

    .title {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .driver-card {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }

    .driver-card img {
        width: 80px; 
    }

    .driver-info h3, .driver-info p {
        font-size: 14px; 
    }

    .call-button {
        width: 90%; 
        margin-top: 10px; 
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .call-button img {
        width: 25px;
    }
}



