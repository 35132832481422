.profile-card {
  width: 500px;
  height: auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 225, 133);
  margin: 20px auto;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.profile-info h1 {
  margin: 10px 0;
  font-size: 32px;
}

.profile-info p {
  margin: 5px 0;
  font-size: 20px;
  font-weight: 400;
}

.profile-status {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.toggle-button {
  padding: 5px 10px;
  margin-right: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  height: 50px;
}

.toggle-button:hover {
  opacity: 0.8;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .profile-card {
    width: 80%;
    padding: 15px;
    box-shadow: 0px 0px 5px #ccc;
    background-color: rgb(255, 225, 133);
    margin-left: 5%;
    margin-right: auto;
  }

  .profile-image img {
    width: 50px;
    height: 50px;
  }

  .profile-info h1 {
    font-size: 20px;
  }

  .profile-info p {
    font-size: 16px;
  }

  .toggle-button {
    padding: 8px 16px;
    font-size: 20px;
  }

  .status-dot {
    width: 8px;
    height: 8px;
  }
}
