.footer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #c7c7c7;
}

.footer-logo-container {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
}


.footer-text{
    display: flex;
    flex-direction: column;
    /* color: #ffffff; */
    margin-left: 10px;
}

.footer-text p {
    margin: 0;
}

.footer-logo {
    width: 100%;
    height: 100%;
}

.footer-watermark {
    text-align: center;
    /* color: #ffffff; */
}   

.footer-social-media {
    display: flex;
    justify-content: space-between;
    /* color: #ffffff; */
    text-decoration: none;
    padding: 10px;
    padding-right: 20px;
}

.footer-social-media-icon {
    color: #c7c7c7;
    text-decoration: none;
}

.footer-social-media p{
    margin: 0;
}

.footer-elements {
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
}

.footer-logo {
    width: 40px;
    height: auto;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        width: 100%;
        padding: 20px 10px;
    }

    .footer-text-container {
        width: 100%;
        display: flex;
        justify-content: start;
    }

    .footer-logo-container{
        width: 100%;
        display: flex;
        justify-content: cent;
        margin-bottom: 10px;
    } 

    .footer-elements {
        flex-direction: column;
        margin-right: 0;
    }

    .footer-social-media {
        flex-direction: column;
        margin: 0;
    }

    .footer-watermark {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.footer a {
    text-decoration: none;
    color: inherit;
}

