.body {
    background-color: #f8f3c6;
    height: 200vh;
    margin-left: 10%;
    margin-right: 10%;
}

.poster-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
}

.poster-image, .poster-content {
    flex: 1;
}

.poster-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.poster-image img {
    width: 100%;
    height: auto;
}

.poster-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.find-now-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
}

.find-now-button:hover {
    background-color: #ffe1008f;
    color: #000;
}

.illustration-image {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
}

.illustration-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    text-align: center;
}

.section-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: rgb(255, 225, 133);
}

.alt-section-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f8f3c6;
    padding-top: 150px;
    padding-bottom: 150px;
}   

.alt-illustration-image {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
}   

.alt-illustration-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    text-align: center;
}

.last-section-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: rgb(255, 225, 133);
}   

.last-illustration-image {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
}   

.last-illustration-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
    .body {
        margin-left: 5%;
        margin-right: 5%;
    }

    .poster-container {
        flex-direction: column;
    }

    .poster-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .illustration-image {
        width: 100%;
        height: auto;
    }

    .illustration-content, .poster-content {
        width: 100%;
        align-items: center;
    }

    .section-container {
        flex-direction: column-reverse;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .alt-section-container {
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .alt-illustration-content {
        width: 100%;
    }
    
    .alt-illustration-image {
        width: 100%;
        height: auto;
    }

    .last-section-container {
        flex-direction: column-reverse;
        padding-top: 50px;
        padding-bottom: 120px;
    }


    .last-section-container {
        flex-direction: column-reverse;
        padding-top: 50px;
        padding-bottom: 300px;
    } 
    .last-illustration-image {
        width: 100%;
        height: auto;
    }
    .last-illustration-content {
        width: 100%;
    }
}


