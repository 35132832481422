.contact-us-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: rgba(255, 225, 133, 0.519);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

.contact-us-container h1 {
  text-align: center;
  font-size: 38px;
}

.contact-us-container h3 {
  font-size: 30px;
  text-align: center;
  font-weight: 400;
}

.contact-us-container p {
  font-size: 20px;
  text-align: center;
}

.contact-us-container a {
  color: #000000;
}

.contact-us-container img {
  width: 500px;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-us-container {
    max-width: 90%;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }

  .contact-us-container h1 {
    font-size: 28px;
  }

  .contact-us-container h3 {
    font-size: 24px;
  }

  .contact-us-container p {
    font-size: 18px;
  }

  .contact-us-container img {
    width: 100%;
    height: auto;
  }
}
