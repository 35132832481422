.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 200px;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 80vh;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    background-color: rgb(255, 225, 133);
    font-size: 16px;
    color: #333;
}

.name-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    background-color: #ebebebc7;
    padding: 10px;
    border-radius: 10px;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin: 8px 2px;
    box-sizing: border-box;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
}

.registration-form button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    height: 40px;
    font-size: 16px;
    margin-bottom: 20px;
}

.registration-form button:hover {
    background-color: #003580;
    height: 40px;
    font-size: 16px;
}    

.register-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input-field {
    flex-grow: 1;
    height: auto;
}

.toggle-password {
    cursor: pointer;
    position: absolute;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }