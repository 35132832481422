.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 225, 133);
    margin-top: 100px;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px #ccc;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.login-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-image img {
    width: 250px;
    height: auto;
}

.login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.login-form input{
    width: 60%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 20px;
}

.login-form button {
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    width: 40%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 40px;
}

.login-form button:hover {
    background-color: #003580;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column; /* Stack the form vertically */
        padding: 10px; /* Reduce padding */
        width: 90%; /* Increase width to take more space */
        margin-top: 50px;
    }

    .login-image {
        display: none; /* Hide the image container on mobile devices */
    }

    .login-form {
        width: 100%; /* Form takes full width of the container */
    }

    .login-form input{
        width: 80%; /* Increase the width of inputs and button */
        font-size: 14px; /* Smaller font size for better fit */
        height: auto; /* Larger height for easier interaction */
    }

    .login-form input {
        padding: 15px; /* Increase padding for better visibility */
    }

    .login-form button {
        padding: 15px;
        font-size: 16px;
        height: auto;
        width: 90%;

    }
}
